import type { NextPage } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { Container } from '@/components/Layout';
import Menu from '@/components/Layout/Header/Menu';
import { helpers } from '@/components/theme';

import IconSpotify from '@root/public/img/icon-spotify.svg';
import IconApple from '@root/public/img/icon-apple.svg';
import IconDeezer from '@root/public/img/icon-deezer.svg';
import IconTidal from '@root/public/img/icon-tidal.svg';
import IconAmazonMusic from '@root/public/img/icon-amazonmusic.svg';
import IconYouTube from '@root/public/img/icon-youtube.svg';
import IconSuaMusica from '@root/public/img/icon-suamusica.svg';
import IconInstagram from '@root/public/img/logo-instagram.svg';
import IconFacebook from '@root/public/img/logo-facebook-square.svg';
import IconTwitter from '@root/public/img/logo-twitter.svg';
import IconTikTok from '@root/public/img/logo-tiktok.svg';

import sanityImage from '@root/utils/sanityImage';

interface Props {
  config: SanitySiteConfig;
  navigation: SanityNavigation[];
  section: SanityHomeHeader;
}

const Wrap = styled.header`
  height: ${props => props.theme.helpers.toRem(1294)};
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    height: auto;
    z-index: 2;
  }

  ${Container} {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .bg {
    top: 0;
    left: 0;
    text-align: center;
    pointer-events: none;
    user-select: none;
    z-index: -1;

    @media screen and (max-width: 1200px) {
      overflow: hidden;
    }

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;

      @media screen and (max-width: 1200px) {
        width: 124%;
        margin-left: -12%;
        max-width: 99999px;
      }
    }
  }

  .menu {
    position: absolute;
    bottom: ${props => props.theme.helpers.toRem(352)};
    z-index: 1;

    @media screen and (max-width: 1920px) {
      bottom: 18.3vw;
    }

    @media screen and (max-width: 1200px) {
      bottom: auto;
    }
  }
`;

const Platforms = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${props => props.theme.helpers.toRem(54)};
  left: ${props => props.theme.helpers.toRem(20)};

  @media screen and (max-width: 1200px) {
    top: 100px;
    left: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  a {
    display: block;
    color: ${props => props.theme.colors.main};

    &:hover,
    &:focus {
      color: ${props => props.theme.colors.secondary};
    }
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    li {
      &:not(:first-child) {
        margin-left: ${props => props.theme.helpers.toRem(12)};

        @media screen and (max-width: 1200px) {
          margin-top: ${props => props.theme.helpers.toRem(20)};
          margin-left: ${props => props.theme.helpers.toRem(0)};
        }
      }

      svg {
        vertical-align: top;
      }
    }
  }

  span {
    margin: 0 0 0 ${props => props.theme.helpers.toRem(18)};
    ${props => props.theme.helpers.fontSize(14)}
    font-weight: 600;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${props => props.theme.helpers.toRem(54)};
  right: ${props => props.theme.helpers.toRem(20)};

  @media screen and (max-width: 1200px) {
    top: 45px;
  }

  a {
    display: block;
    color: ${props => props.theme.colors.secondaryAlt};

    &:hover,
    &:focus {
      color: ${props => props.theme.colors.secondary};
    }
  }

  ul {
    margin: 0;
    display: flex;
    align-items: center;
    list-style: none;

    li {
      &:not(:last-child) {
        margin-right: ${props => props.theme.helpers.toRem(15)};
      }

      svg {
        vertical-align: top;
      }
    }
  }
`;

const Header: NextPage<Props> = ({
  config,
  navigation,
  section
}: Props) => {
  return (
    <Wrap data-aos="fade">
      {section?.bg && <div className="bg">
        <Image
          width={1920}
          height={1294}
          src={sanityImage(section.bg).auto('format').url()}
          alt="Fundo do topo"
          loading="eager" />
      </div>}

      <Container>
        {(config.platforms && config.platforms?.length > 0) && (
          <Platforms data-aos="fade-down" data-aos-delay="400">
            <ul>
              {config.platforms?.map((platform, index) => (
                <li key={`platform-${index}`}>
                  <Link href={platform.url} target="_blank" rel="noopener noreferrer">
                    {platform.type === 'spotify' && (
                      <IconSpotify
                        width={helpers.toRem(24)}
                        height={helpers.toRem(24)}
                        title="Spotify" />
                    )}

                    {platform.type === 'apple-music' && (
                      <IconApple
                        width={helpers.toRem(22)}
                        height={helpers.toRem(22)}
                        title="Apple Music" />
                    )}

                    {platform.type === 'deezer' && (
                      <IconDeezer
                        width={helpers.toRem(30)}
                        height={helpers.toRem(30)}
                        title="Deezer" />
                    )}

                    {platform.type === 'tidal' && (
                      <IconTidal
                        width={helpers.toRem(30)}
                        height={helpers.toRem(30)}
                        title="Tidal" />
                    )}

                    {platform.type === 'amazon-music' && (
                      <IconAmazonMusic
                        width={helpers.toRem(28)}
                        height={helpers.toRem(28)}
                        title="Amazon Music" />
                    )}

                    {platform.type === 'youtube-music' && (
                      <IconYouTube
                        width={helpers.toRem(30)}
                        height={helpers.toRem(30)}
                        title="YouTube Music" />
                    )}

                    {platform.type === 'sua-musica' && (
                      <IconSuaMusica
                        width={helpers.toRem(28)}
                        height={helpers.toRem(28)}
                        title="Sua Música" />
                    )}
                  </Link>
                </li>
              ))}
            </ul>

            {config.highlightedSong && <span>{config.highlightedSong}</span>}
          </Platforms>
        )}

        {(config.networks && config.networks?.length > 0) && (
          <Social data-aos="fade-down" data-aos-delay="400">
            <ul>
              {config.networks?.map((network, index) => (
                <li key={`network-${index}`}>
                  <Link href={network.url} target="_blank" rel="noopener noreferrer">
                    {network.type === 'instagram' && (
                      <IconInstagram
                        width={helpers.toRem(28)}
                        height={helpers.toRem(28)}
                        title="Instagram" />
                    )}

                    {network.type === 'facebook' && (
                      <IconFacebook
                        width={helpers.toRem(28)}
                        height={helpers.toRem(28)}
                        title="Facebook" />
                    )}

                    {network.type === 'twitter' && (
                      <IconTwitter
                        width={helpers.toRem(28)}
                        height={helpers.toRem(28)}
                        title="Twitter" />
                    )}

                    {network.type === 'tiktok' && (
                      <IconTikTok
                        width={helpers.toRem(28)}
                        height={helpers.toRem(28)}
                        title="TikTok" />
                    )}

                    {network.type === 'youtube' && (
                      <IconYouTube
                        width={helpers.toRem(28)}
                        height={helpers.toRem(28)}
                        title="YouTube" />
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </Social>
        )}

        <Menu
          className="menu"
          items={navigation} />
      </Container>
    </Wrap>
  )
};

export default Header;
